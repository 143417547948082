import React, { useState } from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  brandFonts,
  fontWeights,
  fontSize,
  fluidFontSize,
} from '../styles';
import { Svg } from './ui';
import Stars from './Stars';
import { VariantPrice } from './Prices';
import ProductForm from './ProductForm';
import ProductAvailability from './ProductAvailability';
import GreetingsCards from './GreetingsCards';
import googleLogo from '../images/google-logo.svg';
import inStockIcon from '../images/in-stock-icon.svg';
import outOfStockIcon from '../images/out-of-stock-icon.svg';

const StyledProductDetails = styled.section``;

const StyledHeading = styled.h1`
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.regular};
  ${fluidFontSize('28px', '40px', breakpointSizes.tiny, breakpointSizes.large)};
  line-height: 1.2;
`;

const StyledGoogleReviews = styled.p`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 18px;
  font-weight: ${fontWeights.medium};
  ${fontSize(14)};
  line-height: 1;

  ${minBreakpointQuery.large`
    margin-top: 20px;
  `}
`;

const StyledGoogleReviewsStars = styled(Stars)`
  margin-right: 10px;

  svg {
    width: 15px;
    height: 15px;
  }
`;

const StyledGoogleReviewsLogo = styled(Svg)`
  position: relative;
  top: 2px;
  margin-right: 4px;
  height: 14px;
  width: 46px;
`;

const StyledPriceStock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
`;

const StyledPrice = styled(VariantPrice)`
  ${fontSize(26)};

  ${minBreakpointQuery.large`
    ${fontSize(28)};
  `}
`;

const StyledStock = styled.p`
  font-weight: ${fontWeights.medium};

  svg {
    margin-left: 6px;
    height: 24px;
    width: 24px;
    vertical-align: middle;
  }
`;

const StyledShortDescription = styled.p`
  margin-top: 16px;
  line-height: 1.5;

  ${minBreakpointQuery.large`
    margin-top: 20px;
  `}
`;

const StyledCareIcons = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  margin-top: 16px;

  ${minBreakpointQuery.large`
    gap: 20px;
    margin-top: 20px;
  `}
`;

const StyledCareIcon = styled.img`
  display: block;
  margin: auto;
  height: 70px;
  width: 70px;

  ${minBreakpointQuery.tiny`
    height: 80px;
    width: 80px;
  `}

  ${minBreakpointQuery.medium`
    height: 90px;
    width: 90px;
  `}

  ${minBreakpointQuery.large`
    height: 100px;
    width: 100px;
  `}
`;

const ProductDetails = ({
  storefrontId,
  legacyResourceId,
  title,
  handle,
  googleReviews: { averageRating, totalReviewsCount },
  shortDescription,
  careIcons,
  options,
  variants,
  selectedVariant,
  setSelectedVariant,
  greetingsCards,
}) => {
  const [
    selectedVariantAvailability,
    setSelectedVariantAvailability,
  ] = useState(undefined);

  return (
    <StyledProductDetails>
      <StyledHeading>{title}</StyledHeading>
      <StyledPriceStock>
        <StyledPrice variant={selectedVariant} />
        {selectedVariantAvailability === true ? (
          <StyledStock>
            In stock <Svg image={inStockIcon} />
          </StyledStock>
        ) : selectedVariantAvailability === false ? (
          <StyledStock>
            Out of stock <Svg image={outOfStockIcon} />
          </StyledStock>
        ) : (
          ''
        )}
      </StyledPriceStock>
      {shortDescription && (
        <StyledShortDescription>{shortDescription}</StyledShortDescription>
      )}
      {careIcons && careIcons.length > 0 && (
        <StyledCareIcons>
          {careIcons.map(({ url, alt }, id) => (
            <StyledCareIcon key={id} src={url} alt={alt} loading="lazy" />
          ))}
        </StyledCareIcons>
      )}
      <ProductForm
        storefrontId={storefrontId}
        title={title}
        handle={handle}
        options={options}
        variants={variants}
        selectedVariant={selectedVariant}
        setSelectedVariant={setSelectedVariant}
        selectedVariantAvailability={selectedVariantAvailability}
      />
      <ProductAvailability
        storefrontProductId={storefrontId}
        legacyResourceId={legacyResourceId}
        selectedVariant={selectedVariant}
        selectedVariantAvailability={selectedVariantAvailability}
        setSelectedVariantAvailability={setSelectedVariantAvailability}
      />
      <GreetingsCards items={greetingsCards} />
    </StyledProductDetails>
  );
};

export default ProductDetails;
