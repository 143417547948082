import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  brandFonts,
  fontWeights,
  fontSize,
} from '../styles';
import { formatPrice } from '../utils';
import { StoreContext } from '../context/StoreContext';

const StyledGreetingsCards = styled.section`
  margin-top: 20px;

  ${minBreakpointQuery.large`
    margin-top: 25px;
  `}
`;

const StyledText = styled.p`
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};
`;

const StyledItems = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 15px;

  ${minBreakpointQuery.large`
    margin-top: 20px;
  `}
`;

const StyledItem = styled.li``;

const StyledName = styled.span`
  display: block;
  margin-top: 15px;
  font-weight: ${fontWeights.medium};
  ${fontSize(14)};

  ${minBreakpointQuery.large`
    ${fontSize(15)};
  `}
`;

const StyledButton = styled.button`
  margin-top: 12px;
  padding: 2px 0;
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};
  ${fontSize(12)};
  text-decoration: underline;
  background: none;
  border: none;
`;

const GreetingsCards = ({ items }) => {
  const { addProductsToCheckout } = useContext(StoreContext);

  return (
    items.length > 0 && (
      <StyledGreetingsCards>
        <StyledText>Greetings Cards:</StyledText>
        <StyledItems>
          {items.map(({ storefrontId, title, image, price }) => (
            <StyledItem key={storefrontId}>
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={image.altText || title}
              />
              <StyledName>{title}</StyledName>
              <StyledButton
                onClick={async () =>
                  await addProductsToCheckout({
                    variantId: storefrontId,
                    quantity: 1,
                  })
                }
              >
                Add {formatPrice(price)}
              </StyledButton>
            </StyledItem>
          ))}
        </StyledItems>
      </StyledGreetingsCards>
    )
  );
};

export default GreetingsCards;
