import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  standardTransition,
} from '../styles';
import { SelectWrap, Select } from './ui';
import ProductQuantity from './ProductQuantity';
import { StoreContext } from '../context/StoreContext';

const StyledProductForm = styled.form`
  margin-top: 22px;

  ${minBreakpointQuery.large`
    margin-top: 28px;
  `}
`;

const StyledVariants = styled.div`
  margin: 20px 0;
`;

const StyledLabel = styled.label`
  display: block;
  margin-top: 20px;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledText = styled.span`
  display: block;
  margin-bottom: 10px;
`;

const StyledAddToCart = styled.div`
  margin: 20px 0;
`;

const StyledQuantityInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid ${standardColours.lightGrey};
`;

const StyledInfoButton = styled.button`
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  padding-right: 26px;

  span {
    border-bottom: 1px solid ${standardColours.grey};
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 1px;
    right: 4px;
    top: calc(50% - 4px);
    border: 2px solid ${standardColours.black};
    border-top: none;
    border-left: none;
    margin-top: 2px;
    z-index: 1;
    transform: translateY(-50%) rotate(45deg);
    transition: ${standardTransition('border-color')};
  }
`;

const StyledButtons = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${standardColours.lightGrey};
`;

const StyledCartButton = styled.button`
  padding: 20px;
  width: 100%;
  color: ${standardColours.white};
  font-weight: ${fontWeights.medium};
  line-height: 1.5;
  background-color: ${brandColours.primary};
  border: none;
`;

const ProductForm = ({
  options,
  variants,
  selectedVariant,
  setSelectedVariant,
  selectedVariantAvailability,
}) => {
  const { checkout, addProductsToCheckout } = useContext(StoreContext);

  const [quantity, setQuantity] = useState(1);

  const [addingToCheckout, setAddingToCheckout] = useState(false);

  const changeQuantity = e => {
    const value = e.target.value;

    if (value === 'remove') {
      setQuantity(quantity > 1 ? quantity - 1 : 1);
    } else if (value === 'add') {
      setQuantity(quantity ? quantity + 1 : 1);
    } else {
      setQuantity(parseInt(e.target.value));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const lineItems = [
      {
        variantId: selectedVariant.storefrontId,
        quantity: quantity || 1,
      },
    ];

    setAddingToCheckout(true);
    await addProductsToCheckout(lineItems);
    setAddingToCheckout(false);
    setQuantity(1);
  };

  return (
    (variants.length > 1 || selectedVariantAvailability === true) && (
      <StyledProductForm onSubmit={e => handleSubmit(e)}>
        {variants.length > 1 && (
          <StyledVariants>
            <StyledLabel htmlFor="product-form-variants">
              <StyledText>
                Select {options.map(({ name }) => [name]).join(' & ')}
              </StyledText>
              <SelectWrap>
                <Select
                  id="product-form-variants"
                  value={selectedVariant.storefrontId}
                  onChange={e => {
                    setSelectedVariant(
                      variants.find(
                        variant => variant.storefrontId === e.target.value
                      )
                    );
                  }}
                >
                  {variants.map(({ id, storefrontId, title }) => (
                    <option key={id} value={storefrontId}>
                      {title}
                    </option>
                  ))}
                </Select>
              </SelectWrap>
            </StyledLabel>
          </StyledVariants>
        )}
        {selectedVariantAvailability && (
          <StyledAddToCart>
            <StyledQuantityInfo>
              <ProductQuantity
                quantity={quantity}
                changeFunction={changeQuantity}
              />
              <StyledInfoButton
                type="button"
                onClick={() =>
                  document
                    .getElementById('product-info')
                    .scrollIntoView({ behavior: 'smooth' })
                }
              >
                <span>More Info</span>
              </StyledInfoButton>
            </StyledQuantityInfo>
            <StyledButtons>
              <StyledCartButton
                disabled={!selectedVariantAvailability || addingToCheckout}
              >
                {addingToCheckout ? 'Adding...' : 'Add To Bag'}
              </StyledCartButton>
            </StyledButtons>
          </StyledAddToCart>
        )}
      </StyledProductForm>
    )
  );
};

export default ProductForm;
